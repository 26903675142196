import React, { Component } from "react";
import _ from "lodash";
import TitleTab from "./components/title-tab";
import MusicPlayer from "./components/music-player";
import { getItems } from "./drawer-items";

function ignore() {}

class TabTest extends Component {
  constructor(props) {
    super(props);
    let item = _.clone(getItems()[0]);

    item.position = "right";
    item.title = "Go And Say Goodbye";
    item.title = "Nowadays Clancy Can't Even Sing";
    /* item.title = "ullamco reprehenderit"*/
    item.tab = "tab-1";
    item.sticker = "none";
    item.sticker = "blue-1";
    item.stickerStyle = undefined;
    item.stickerStyle = "type-2";
    item.stickerStyle = "type-1";

    this.state = { item };
  }
  render() {
    let { item } = this.state;
    let style = { position: "absolute", top: "50%", left: "-400px" };
    return (
      <div style={style}>
        <TitleTab item={item} index={-1} onClick={ignore} />
      </div>
    );
  }
}

class MusicPlayerTest extends Component {
  render() {
    let style = { margin: "0px auto", width: "83px", backgroundColor: "tan" };
    return (
      <div style={style}>
        <MusicPlayer />
      </div>
    );
  }
}

export default class TestContainer extends Component {
  render() {
    return <MusicPlayerTest />;
  }
}
