import React, { Component } from "react";
import _ from "lodash";
import cn from "classnames";
import YearsDisplayHorizontal from "./components/years-display-horizontal";
import BubbleInfo from "./components/bubble-info";
import LyricsModal from "./components/lyrics-modal";
import PhotoModal from "./components/photo-modal";
import TourZI from "./components/tour-zi";
import { TourMonth } from "./components/tours/tourMonth";
import { Pages } from "./components/timeline-pages";
import { allMonths as mths } from "./stubs/gen-months";
import clamp from "./util/clamp";
import { withRouter } from "react-router-dom";
import {
  sortedItems,
  getTrackById,
  getTimelineTopHalfContentByYear,
  getTimelineZIEventById,
  getAlbumById,
  getToggles,
} from "./drawer-items";

import {
  tracksForYearAndMonth,
  percentageForTrack,
  availableTracksToNavigate,
  findPreviousTrackToNavigate,
  findNextTrackToNavigate,
} from "./track-positions";

import { validMonth, monthNumber, monthName } from "./util/months";
import { setCurrentDate, setCurrentTrack } from "./currentDate";
import Easing from "./util/easing";
import { resetMetadata } from "./actions/metas";

import {
  albumIsLockedByID,
  filmIsLockedByID,
  infoCardIsLockedByID,
} from "./user";

import label from "../images/timeline/NYA_Button_Release_Solo.webp";

import tile01 from "../images/timeline-months/tile01.jpg";
import tile02 from "../images/timeline-months/tile02.jpg";
import tile03 from "../images/timeline-months/tile03.jpg";
import tile04 from "../images/timeline-months/tile04.jpg";
import tile05 from "../images/timeline-months/tile05.jpg";
import tile06 from "../images/timeline-months/tile06.jpg";
import tile07 from "../images/timeline-months/tile07.jpg";
import tile08 from "../images/timeline-months/tile08.jpg";
import tile09 from "../images/timeline-months/tile09.jpg";
import tile10 from "../images/timeline-months/tile10.jpg";
import tile11 from "../images/timeline-months/tile11.jpg";
import tile12 from "../images/timeline-months/tile12.jpg";

const tiles = [
  tile01,
  tile02,
  tile03,
  tile04,
  tile05,
  tile06,
  tile07,
  tile08,
  tile09,
  tile10,
  tile11,
  tile12,
];

let allMonths = mths.slice(); /* copy so we can add spacers */
allMonths.unshift({ spacer: true, id: -1 });
allMonths.push({ spacer: true, id: -2 });

const count = allMonths.length;
const minItemIndex = 1;
const maxItemIndex = allMonths.length - 2;

const pagePercentage =
  (1 / (count - 1)) * 100; /* percentage width of a single page */

function getPercentForIndex(idx) {
  return (idx / (count - 1)) * 100;
}

let pageHeightToWidth = 720 / 980;

/* we don't actually view from 0 to 100%, it is inset a bit
 * to handle the edge cases at the beginning and end of the timeline
 * */
function getMinPercent() {
  return pagePercentage * 2;
}

/* the max percentage position that keeps the right edge
   of the last page flush to right edge of the viewport */
function getMaxPercent() {
  let { width, pageW } = Pages.measure(allMonths, pageHeightToWidth);

  let pct = getPercentForIndex(maxItemIndex);
  let pw = width / pageW - 2;

  return pct - pw * pagePercentage;
}

//percentage value in the timeline for a given year
function getPercent(obj) {
  let index = _.findIndex(allMonths, obj);
  //handle the edge cases of the first and last pages
  if (index <= minItemIndex) return getMinPercent();
  if (index >= maxItemIndex) return getMaxPercent();

  let pct = getPercentForIndex(index);
  return pct;
}

//percentage to adjust the initial position when we link in
function getCenteringAdjustment(percentageInMonth = 50) {
  /* let index = _.findIndex(allMonths, obj)

     * if (index <= minItemIndex) return 0*/

  //to display month flush left return pagePercentage
  //returning 0 gives one month of padding on left

  let count = Pages.getVisiblePageCount(allMonths, pageHeightToWidth);

  let offset = 1 + percentageInMonth / 100;
  let centerAtLeft = pagePercentage * offset;
  let halfScreenWidth = count * pagePercentage * 0.5;

  return centerAtLeft - halfScreenWidth;
}

function offsetForDay(day) {
  let result = (day / 31) * 100;
  return result;
}

function calcAlbumPosition({ releaseDate, completionDate }) {
  const { completion = false } = getToggles();
  const albumDate =
    completion && completionDate && completionDate.hasOwnProperty("day")
      ? completionDate
      : releaseDate;
  let { day } = albumDate;
  return offsetForDay(day);
}

function getMaxAlbumPosition() {
  let pageWidth = 980;
  let albumWidth = 162 * 1.5 + 20;
  let width = pageWidth - albumWidth;
  let percent = width / pageWidth;

  return percent * 100;
}

function albumPositioner(seen, isPrev) {
  return function (album) {
    let pos = Math.floor(calcAlbumPosition(album) / 5) * 5;

    if (isPrev) pos -= 100;

    while (seen[pos]) {
      pos += 5;
    }

    seen[pos] = true;
    return pos;
  };
}

function getTopHalfItemsByYearAndMonth(yearNum, monthNum, completion = false) {
  let year, month;
  if (monthNum < 1) {
    month = 12 - monthNum;
    year = yearNum - 1;
  } else {
    month = monthNum;
    year = yearNum;
  }

  return _.filter(getTimelineTopHalfContentByYear(year) || [], (item) => {
    let itemMonth = item.hasOwnProperty("releaseAlbum")
      ? item.releaseSticker
        ? item.timelineReleaseDate.month
        : item.releaseDate.month
      : item.releaseDate.month;
    if (item.hasOwnProperty("completionAlbum") && completion) {
      itemMonth = item.completionDate.month;
    }

    return itemMonth === month;
  });
}

/* we need to keep the album within the page with enough room on the
   right to show the whole album and its drop shadow. can't overlap
   the page to the right due to stacking order issues, so we push
   items that /would/ overlap on the right into the next page and
   position them appropiately */
let itemTable = {};
function getTopHalfItemsDisplayedByYearAndMonth(year, month, completion) {
  let key = `${year}|${month}`;

  if (!itemTable[key]) {
    let prev = sortedItems(
      getTopHalfItemsByYearAndMonth(year, month - 1, completion) || []
    );
    let curr = sortedItems(
      getTopHalfItemsByYearAndMonth(year, month, completion) || []
    );

    let seen = {};
    let positionPrev = albumPositioner(seen, true);
    let positionCurr = albumPositioner(seen);

    prev = _.map(prev, (album) => ({ album, position: positionPrev(album) }));
    curr = _.map(curr, (album) => ({ album, position: positionCurr(album) }));

    let maxCurrPosition = getMaxAlbumPosition();
    let maxPrevPosition = maxCurrPosition - 100;

    prev = _.filter(prev, ({ position }) => position > maxPrevPosition);
    curr = _.filter(curr, ({ position }) => position < maxCurrPosition);

    let items = prev.concat(curr);

    /* calculates the amount other albums overlap the given album
     * so that we know where to position the info bubble */
    function coveringAmount(index) {
      let albumWidth = 162;
      let pageWidth = 720;
      let stepSize = 0.05 * pageWidth;

      let amount = stepSize;
      let position = items[index].position + 5;
      let curr = seen[position];

      while (amount < albumWidth) {
        if (curr) {
          return (amount / albumWidth) * 100 + 5;
        }
        position += 5;
        curr = seen[position];
        amount += stepSize;
      }

      let width = Math.min(albumWidth, amount);
      return (width / albumWidth) * 100;
    }
    let covering = items.map((_, index) => coveringAmount(index));

    itemTable[key] = { items, covering };
  }
  return itemTable[key];
}

function currentItem(pct) {
  return allMonths[currentIndex(pct)];
}
function currentIndex(pct) {
  let len = allMonths.length - 1;
  return Math.floor(len * (pct / 100));
}

export class TimelineMonthsContainer extends Component {
  constructor(props, b) {
    super(props, b);

    const searchParams = new URLSearchParams(props.location.search);
    this.state = this.extractStateFromQueryParams(searchParams);
    this.updateCurrDate(this.state);

    this.onScroll = this.onScroll.bind(this);
    this.viewTrack = this.viewTrack.bind(this);
    this.showAlbum = this.showAlbum.bind(this);
    this.showFilm = this.showFilm.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.getPageContents = this.getPageContents.bind(this);
    this.clickTrack = this.clickTrack.bind(this);
    this.hoverAlbum = this.hoverAlbum.bind(this);
    this.clickAlbum = this.clickAlbum.bind(this);
    this.clickItem = this.clickItem.bind(this);
    this.showTour = this.showTour.bind(this);
    this.hideItem = this.hideItem.bind(this);
    this.clickViewAlbum = this.clickViewAlbum.bind(this);
    this.clickShowTracks = this.clickShowTracks.bind(this);
    this.clickViewTrack = this.clickViewTrack.bind(this);
    this.jumpToTrack = this.jumpToTrack.bind(this);
    this.jumpToAlbum = this.jumpToAlbum.bind(this);
    this.hideOverview = this.hideOverview.bind(this);
    this.hideAlbumBubble = this.hideAlbumBubble.bind(this);
    this.hideTrackBubble = this.hideTrackBubble.bind(this);
    this.scrollByPagePercentage = this.scrollByPagePercentage.bind(this);
    this.hideBubbleAndTracks = this.hideBubbleAndTracks.bind(this);
    this.onYearClick = this.onYearClick.bind(this);
    this.updateYearUrl = this.updateYearUrl.bind(this);
    this.updatePositionUrl = this.updatePositionUrl.bind(this);
    this.highlightNextTrack = this.highlightNextTrack.bind(this);
    this.highlightPrevTrack = this.highlightPrevTrack.bind(this);
    this.showEventModal = this.showEventModal.bind(this);
    this.hideEventModal = this.hideEventModal.bind(this);
    this.showPhotoModal = this.showPhotoModal.bind(this);
    this.photoModalOnUpdate = this.photoModalOnUpdate.bind(this);
    this.hidePhotoModal = this.hidePhotoModal.bind(this);
    this.mouseEnterItem = this.mouseEnterItem.bind(this);
    this.mouseLeaveItem = this.mouseLeaveItem.bind(this);
    this.animateTo = this.animateTo.bind(this);
    this.updateMonthNum = this.updateMonthNum.bind(this);
  }
  mouseEnterItem() {
    this.setState({ hovering: true });
  }
  mouseLeaveItem() {
    this.setState({ hovering: false });
  }
  extractStateFromQueryParams(searchParams) {
    let position = null,
      date = null,
      currentItemId = null,
      currentItemIndex = null,
      photoModalState = null,
      currentTrackId = null,
      currentAlbumId = null,
      highlightedAlbumId = null,
      previousTrackId = undefined;

    let year,
      month,
      day = null;

    if (searchParams.has("track")) {
      let id = searchParams.get("track");
      let track = getTrackById(id);

      if (searchParams.has("year") && searchParams.has("month")) {
        year = parseInt(searchParams.get("year"), 10);
        month = searchParams.get("month");
      } else {
        year = track.releaseDate.year;
        month = monthName(track.releaseDate.month);
        day = track.releaseDate.day;
      }

      currentTrackId = id;
    } else if (searchParams.has("album") && searchParams.get("album")) {
      let id = searchParams.get("album");
      let album = getAlbumById(id);

      year = album.releaseDate.year;
      month = monthName(album.releaseDate.month);
      day = album.releaseDate.day;
      currentAlbumId = id;

      previousTrackId = album.tracksForTimeline[0].id;
    } else {
      year = searchParams.has("year")
        ? parseInt(searchParams.get("year"), 10)
        : null;
      month = searchParams.has("month") ? searchParams.get("month") : null;
      day = searchParams.has("day")
        ? parseInt(searchParams.get("day"), 10)
        : null;
    }

    if ( searchParams.has("currItem")) {
      currentItemId = searchParams.get("currItem");
    }
    if (searchParams.has("currItemIndex")) {
      currentItemIndex = parseInt(searchParams.get("currItemIndex"), 10);
    }

    if (searchParams.has("photo") && currentItemId) {
      let event = getTimelineZIEventById(currentItemId);
      if (event && event.photos) {
        let collection = event.photos;
        let current = _.find(collection, { id: searchParams.get("photo") });

        photoModalState = { current, collection };
      }
    }

    let offset = 50;

    if (searchParams.has("viewTrack")) {
      let track = getTrackById(searchParams.get("viewTrack"));
      offset = percentageForTrack(track);
      year = track.releaseDate.year;
      month = monthName(track.releaseDate.month);
      day = null;
    }

    if (month && month.match(/^\d+$/)) month = monthName(parseInt(month, 10));

    if (!_.isFinite(year)) year = allMonths[minItemIndex].year;
    if (!validMonth(month)) month = allMonths[minItemIndex].month;

    month = month.toLowerCase();

    date = { month, year };

    if (day !== null) {
      date.day = day;
      offset = offsetForDay(day);
    }

    if (searchParams.has("currTrack")) {
      offset = percentageForTrack(getTrackById(searchParams.get("currTrack")));
    }
    if (searchParams.has("currAlbum")) {
      offset = calcAlbumPosition(getAlbumById(searchParams.get("currAlbum")));
    }

    if (searchParams.has("prevTrack")) {
      previousTrackId = searchParams.get("prevTrack");
    }

    if (searchParams.has("position")) position = parseFloat(searchParams.get("position"));
    else
      position = getPercent({ year, month }) + getCenteringAdjustment(offset);

    let min = getMinPercent();
    let max = getMaxPercent();

    position = Math.min(Math.max(position, min), max);

    if (searchParams.has("currTrack")) currentTrackId = searchParams.get("currTrack");

    if (searchParams.has("currAlbum")) currentAlbumId = searchParams.get("currAlbum");

    if (searchParams.has("hl")) highlightedAlbumId = searchParams.get("hl");

    if (!date) date = currentItem(position);

    let album = null;
    let roomOnRight = true;

    return {
      position,
      date,
      album,
      currentItemId,
      currentItemIndex,
      photoModalState,
      previousTrackId,
      currentAlbumId,
      currentTrackId,
      highlightedAlbumId,
      roomOnRight,
    };
  }
  updateCurrDate(state) {
    if (state.currentTrackId)
      setCurrentTrack(getTrackById(state.currentTrackId));
    else setCurrentDate(this.getCenteredYearAndMonth(state.position));
  }
  componentWillMount() {
    resetMetadata();
  }

  UNSAFE_componentWillUpdate(newprops) {
    const oldSearchParams = new URLSearchParams(this.props.location.search);
    const newSearchParams = new URLSearchParams(newprops.location.search);

    if (oldSearchParams.toString() !== newSearchParams.toString()) {
      let state = this.extractStateFromQueryParams(newSearchParams);
      this.updateCurrDate(state);
      this.setState(state);
    }
  }

  onYearClick(year, onDone) {
    this.jumpToDate({ year, month: "jan" }, undefined, onDone);
  }
  viewTrack(id) {
    this.props.history.push(`/info-card?track=${id}`);
  }
  showAlbum({ id }) {
    this.props.history.push(`/album?id=${id}`);
  }
  showFilm({ id }) {
    this.props.history.push(`/film?id=${id}`);
  }
  updateYearUrl(merge = false) {
    let query = this.getCenteredYearAndMonth();
    query.position = null;
    this.updateUrl(query, merge);
  }
  updateUrl(q, merge = false, push = false) {
    const { history, location } = this.props;
    const pathname = location.pathname;

    // Crear un nuevo objeto URLSearchParams
    const searchParams = new URLSearchParams(merge ? location.search : "");

    // Añadir o eliminar parámetros según corresponda
    Object.keys(q).forEach((key) => {
      if (q[key] === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, q[key]);
      }
    });

    // Construir la nueva URL
    const search = searchParams.toString();
    const newUrl = `${pathname}${search ? `?${search}` : ""}`;

    // Usar history.push o history.replace según corresponda
    if (push) {
      history.push(newUrl);
    } else {
      history.replace(newUrl);
    }
  }
  highlightPrevTrack() {
    let { highlightedAlbumId, previousTrackId } = this.state;
    let track = findPreviousTrackToNavigate(
      highlightedAlbumId,
      null,
      previousTrackId
    );
    if (!track) return;
    this.jumpToTrack(track, undefined, false);
  }
  highlightNextTrack() {
    let { highlightedAlbumId, previousTrackId } = this.state;
    let track = findNextTrackToNavigate(
      highlightedAlbumId,
      null,
      previousTrackId
    );
    if (!track) return;
    this.jumpToTrack(track, undefined, false);
  }
  onScroll(percentage) {
    let min = getMinPercent();
    let max = getMaxPercent();
    let position = Math.min(Math.max(percentage, min), max);

    if (position === this.state.position) return;

    let date = currentItem(position);

    this.setState({ position, date });
  }
  scrollByPagePercentage(pct) {
    let { position } = this.state;
    position += pct * pagePercentage;
    position = clamp(position, getMinPercent(), getMaxPercent());
    this.setState({ position });
  }
  jumpToTrack(track, newAlbumId = undefined, showCurrTrack = true) {
    let { highlightedAlbumId } = this.state;
    let { id, album } = track;
    let { year, month } = track.releaseDate;

    let albumId = newAlbumId || highlightedAlbumId || album.id;

    let offset = percentageForTrack(track);

    month = monthName(month).toLowerCase();

    let date = { year, month };
    let position = getPercent(date) + getCenteringAdjustment(offset);

    this.animateTo(position, 30, () => {
      let path;

      if (showCurrTrack)
        path = `/timeline-months?currTrack=${id}&hl=${albumId}&position=${position}`;
      else
        path = `/timeline-months?prevTrack=${id}&hl=${albumId}&position=${position}`;

      this.props.history.push(path);
    });
  }
  jumpToAlbum(album) {
    let { year, month } = album.releaseDate;

    month = monthName(month);

    let offset = calcAlbumPosition(album);
    let date = { year, month };

    this.jumpToDate(date, offset);
  }
  jumpToDate(date, offset = 50, onDone) {
    let position = getPercent(date) + getCenteringAdjustment(offset);
    this.animateTo(position, 30, () => {
      this.updatePositionUrl();
      onDone && onDone();
    });
  }
  updatePositionUrl() {
    this.updateUrl(
      { position: this.state.position, month: null, year: null },
      true
    );
  }
  animateTo(newPosition, fpsNumber, onDone = () => {}) {
    this.setState({ animate: true });
    let { position } = this.state;
    let delta = newPosition - position;
    let duration = Math.abs(delta) < pagePercentage ? 500 : 1000;
    let fps = fpsNumber;
    let frame = 1000 / fps;
    let frameCount = duration / frame;
    let step = 1.0 / frameCount;

    let current = 0;
    let currentFrame = 0;

    let interval = setInterval(() => {
      currentFrame++;
      current += step;
      let pos = Easing.easeInOutQuad(current) * delta + position;
      this.onScroll(pos);
      if (currentFrame >= frameCount) {
        clearInterval(interval);
        this.onScroll(newPosition);
        onDone();
      }
    }, frame);
  }
  hideOverview(e) {
    e.preventDefault();
    e.stopPropagation();
    this.updateUrl({ hl: null, position: this.state.position }, true);
    return false;
  }
  hideBubbleAndTracks() {
    /* this.setState({currentAlbumId:null,
     *                currentTrackId:null,
     *                highlightedAlbumId:null})*/
    this.updateUrl(
      {
        currAlbum: null,
        currTrack: null,
        currItem: null,
        currItemIndex: null,
        position: this.state.position,
      },
      true
    );
  }
  roomOnRight(el) {
    let windowWidth = window.innerWidth;
    let rect = el.getBoundingClientRect();
    let { left, width } = rect;
    let remaining = windowWidth - (left + width);
    let slack = windowWidth / 5; //TODO: calculate this appropriately

    return remaining > slack;
  }
  clickTrack(e) {
    e.preventDefault();
    e.stopPropagation();

    let el = e.target;

    let currentTrackId = el.getAttribute("data-track");
    let currentAlbumId = null;
    let roomOnRight = this.roomOnRight(el);

    this.setState({ currentTrackId, currentAlbumId, roomOnRight });
    this.updateUrl(
      {
        currTrack: currentTrackId,
        currAlbum: currentAlbumId,
        currItem: null,
        currItemIndex: null,
      },
      true
    );
    /* this.updateUrl({track: currentTrackId}) */
  }
  hideTrackBubble() {
    this.setState({ currentTrackId: null });
    this.updateUrl(
      {
        currTrack: null, // track:null,
        position: this.state.position,
      },
      true
    );
  }
  hoverAlbum(e) {
    if (this.state.currentAlbumId) return;
    this.clickAlbum(e);
  }
  clickAlbum(e) {
    e.preventDefault();
    e.stopPropagation();

    let el = e.target;

    let currentTrackId = null;
    let currentAlbumId = el.getAttribute("data-id");
    let roomOnRight = this.roomOnRight(el);

    if (!currentAlbumId) return;

    if (!getAlbumById(currentAlbumId)) {
      //TODO: handle films and box sets. need to ensure they integrate with track navigation.
      console.log("can't handle item with id: ", currentAlbumId);
      return;
    }

    this.setState({ currentTrackId, currentAlbumId, roomOnRight });
    this.updateUrl(
      {
        currTrack: currentTrackId,
        currAlbum: currentAlbumId,
        currItem: null,
        currItemIndex: null,
      },
      true
    );
    /* this.updateUrl({album: currentAlbumId}) */
  }
  hideAlbumBubble() {
    this.setState({ currentAlbumId: null });
    this.updateUrl(
      { currAlbum: null, album: null, position: this.state.position },
      true
    );
  }
  clickItem(e) {
    e.preventDefault();
    e.stopPropagation();

    let el = e.target;
    let currItem = el.getAttribute("data-id");
    this.updateUrl({ currTrack: null, currAlbum: null, currItem }, true);
  }
  showTour(id, idx) {
    this.updateUrl(
      { currItem: id, currItemIndex: idx, currAlbum: null, currTrack: null },
      true
    );
  }
  updateMonthNum(month) {
    this.setState({ monthSelectedNum: month });
  }

  showEventModal(event) {
    this.setState({ eventForModal: event });
  }
  hideEventModal() {
    this.setState({ eventForModal: null });
  }
  showPhotoModal(event) {
    let { photos } = event;
    let current = photos[0];
    this.updateUrl({ photo: current.id, event: event.id }, true, true);
  }
  photoModalOnUpdate(photo) {
    this.updateUrl({ photo: photo.id }, true);
  }
  hidePhotoModal() {
    this.props.history.goBack();
  }
  hideItem(e) {
    this.updateUrl(
      { currItem: null, currItemIndex: null, position: this.state.position },
      true
    );
  }
  clickViewAlbum(e) {
    e.preventDefault();
    e.stopPropagation();

    let id = e.target.getAttribute("data-id");
    let album = getAlbumById(id);
    if (album && album.hasOwnProperty("isAlbum")) {
      setCurrentTrack(album);
    }

    if (id.toLowerCase()[0] === "f") {
      this.showFilm({ id });
    } else {
      this.showAlbum({ id });
    }
  }
  clickShowTracks(e) {
    e.preventDefault();
    e.stopPropagation();

    let id = e.target.getAttribute("data-id");
    let willShow = this.state.highlightedAlbumId !== id;

    if (willShow) {
      let album = getAlbumById(id);
      let track = album.tracksForTimeline[0];
      this.jumpToTrack(track, id, false);
    } else {
      this.updateUrl({ hl: null, currTrack: null, prevTrack: null }, true);
    }
  }
  clickViewTrack(e) {
    e.preventDefault();
    e.stopPropagation();

    let id = e.target.getAttribute("data-id");
    this.props.history.push(`/info-card?track=${id}`);
  }
  getAlbums(year, monthNum) {
    const {
      original = true,
      completion = false,
      additional = true,
      bootleg = true,
    } = getToggles();
    let content = getTopHalfItemsDisplayedByYearAndMonth(
      year,
      monthNum,
      completion
    );

    let items = content.items;
    let covering = content.covering;
    let albums = _.map(items, ({ album }) => album);
    let positions = _.map(items, ({ position }) => position);

    albums = albums.filter((item) => {
      let hideFromTL =
        "hideFromTimeLine" in item && item.hideFromTimeLine == false;
      if (
        (item.type !== "album" && !hideFromTL) ||
        (original && item.original && hideFromTL) ||
        (additional && item.additional && hideFromTL) ||
        (completion && item.completionDateExist && hideFromTL) ||
        (bootleg && item.bootLeg)
      )
        return true;
      return false;
    });
    let { currentAlbumId, currentItemId, roomOnRight } = this.state;
    return albums.map((album, idx) => {
      let { id, type, unreleased } = album;
      let disabled = false;
      if (album.isFilm) {
        disabled = filmIsLockedByID(album.id);
      } else if (album.isAlbum) {
        disabled = albumIsLockedByID(album.id);
      }

      let releaseLabel = album.releaseSticker ? label : "";
      let bootLegItem = album.bootLeg;
      let released = album.hasOwnProperty("releaseAlbum")
        ? album.releaseSticker
          ? "released"
          : " hidden"
        : "";
      let display = album.hasOwnProperty("releaseAlbum")
        ? album.releaseSticker
          ? `block`
          : `none`
        : album.hasOwnProperty("completionAlbum")
        ? completion
          ? "block"
          : "none"
        : completion
        ? album.completionDateExist
          ? "none"
          : "block"
        : "block";
      // hide empty releasedate albums from TL
      if (!completion && album.emptyReleaseDate) display = "none";

      let isAlbum = type === "album";

      let selected = isAlbum ? currentAlbumId === id : currentItemId === id;

      let cx = cn("album", "month-view", {
        selected,
        unreleased,
        disabled,
        released,
        bootLegItem,
      });
      // film image in zoom in
      let backgroundImage = album.isFilm
        ? `url('${album.image}')`
        : `url('${album.timelineImage}')`;

      let showInfo = isAlbum ? this.clickAlbum : this.clickItem;
      let hideInfo = isAlbum ? this.hideAlbumBubble : this.hideItem;

      let { highlightedAlbumId } = this.state;

      let left = positions[idx] + "%";
      let cover = covering[idx];

      let side = roomOnRight ? "right" : "left";

      return (
        <div
          key={idx}
          className={cx}
          data-id={id}
          data-type={type}
          onClick={showInfo}
          onMouseEnter={undefined /*disabled && this.mouseEnterItem*/}
          onMouseLeave={undefined /*disabled && this.mouseLeaveItem*/}
          style={{ left, display }}
        >
          {selected ? (
            <BubbleInfo
              side={side}
              spacing="110%"
              onClose={hideInfo}
              object={album}
              highlightedAlbumId={highlightedAlbumId}
              onView={this.clickViewAlbum}
              onTracks={this.clickShowTracks}
            />
          ) : null}
          <div className={`bg ${released}`} style={{ backgroundImage }}>
            <div
              className="releaseLabel"
              style={{ backgroundImage: `url(${releaseLabel})` }}
            ></div>
          </div>
        </div>
      );
    });
  }
  getTracks(year, monthNum) {
    let columns = tracksForYearAndMonth(year, monthNum);
    if (!columns) return null;

    let { currentTrackId, roomOnRight, highlightedAlbumId } = this.state;

    let toTrack = (track, idx) => {
      let cx = cn({
        track: true,
        highlight: track.presentOn[highlightedAlbumId],
      });

      let side = roomOnRight ? "right" : "left";

      let selected = track.id === currentTrackId;

      const disabled = infoCardIsLockedByID(track.id);

      return (
        <div className={cx} key={idx}>
          {selected ? (
            <BubbleInfo
              side={side}
              spacing="200%"
              onClose={this.hideTrackBubble}
              object={track}
              onViewAlbum={this.clickViewAlbum}
              onView={this.clickViewTrack}
            />
          ) : null}
          <div
            className="track-indicator"
            data-track={track.id}
            onClick={this.clickTrack}
          />
        </div>
      );
    };

    let toColumn = (tracks, idx) => {
      return (
        <div key={idx} className="column">
          {tracks.map(toTrack).reverse()}
        </div>
      );
    };

    columns = columns.map(toColumn);

    return (
      <div className="tracks">
        <div className="all-tracks">{columns}</div>
      </div>
    );
  }
  getYearElements(year) {
    if (year[0] === "1") {
      return (
        <span>
          <span className="one">1</span>
          {year.substr(1)}
        </span>
      );
    } else {
      return year;
    }
  }
  getPageContents(pageProps) {
    let props = pageProps;

    let { year, month } = props.item;

    let monthNum = monthNumber(month);
    let yearText = monthNum === 1 ? this.getYearElements(year + "") : "";

    let albums = this.getAlbums(year, monthNum, props);
    let tracks = this.getTracks(year, monthNum, props);

    let backgroundImage = `url('${tiles[monthNum - 1]}')`;

    return (
      <div className="month" style={{ backgroundImage }}>
        <TourZI
          year={year}
          selectedEventId={this.state.currentItemId}
          selectedEventIndex={this.state.currentItemIndex}
          month={monthNum}
          onClick={this.showTour}
          onClose={this.hideItem}
          showMoreInfo={this.showEventModal}
          showPhotos={this.showPhotoModal}
        />
        <TourMonth
          year={year}
          selectedEventId={this.state.currentItemId}
          selectedEventIndex={this.state.currentItemIndex}
          month={monthNum}
          updateMonthNum={this.updateMonthNum}
          monthSelectedNum={this.state.monthSelectedNum}
          onClick={this.showTour}
          onClose={this.hideItem}
          showMoreInfo={this.showEventModal}
          showPhotos={this.showPhotoModal}
        />
        {yearText && <div className="month-year">{yearText}</div>}
        {albums}
        {tracks}
      </div>
    );
  }
  getCenteredYearAndMonth(position = this.state.position) {
    let count = Pages.getVisiblePageCount(allMonths, pageHeightToWidth);
    let centeringAdjustment = (count / 2) * pagePercentage - pagePercentage;

    let { year, month } = currentItem(position + centeringAdjustment);

    return { year, month };
  }
  handleZoom() {
    const { history } = this.props;
    let query = this.getCenteredYearAndMonth();
    let pathname = "/timeline";

    const searchParams = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      searchParams.set(key, query[key]);
    });

    const search = searchParams.toString();
    const newUrl = `${pathname}${search ? `?${search}` : ""}`;

    history.push(newUrl);
  }
  render() {
    let min = getMinPercent();
    let max = getMaxPercent();
    let { position } = this.state;

    let width = 720;
    let height = 980;
    let pageHeightToWidth = width / height;

    let centeredYear = this.getCenteredYearAndMonth().year;

    let {
      highlightedAlbumId,
      currentTrackId,
      hovering,
      eventForModal,
      photoModalState,
      previousTrackId,
    } = this.state;

    let { moreLeft, moreRight } = availableTracksToNavigate(
      highlightedAlbumId,
      currentTrackId,
      previousTrackId
    );

    return (
      <div id="timeline" className={cn("months", { hovering })}>
        <div className="pages-wrapper">
          <Pages
            pct={position}
            scrollByPagePercentage={this.scrollByPagePercentage}
            pixelScrollEnded={this.updatePositionUrl}
            pageHeightToWidth={pageHeightToWidth}
            pageWidth={width}
            pageHeight={height}
            items={allMonths}
            getPageContents={this.getPageContents}
            onAlbumSelect={this.showAlbum}
            sliderAffordance={true}
            sliderMin={min}
            sliderMax={max}
            sliderOnScroll={this.onScroll}
            sliderOnEnd={this.updatePositionUrl}
            hideBubbleAndTracks={this.hideBubbleAndTracks}
            currentAlbumId={this.state.currentAlbumId}
            currentItemId={this.state.currentItemId}
            currentItemIndex={this.state.currentItemIndex}
            currentTrackId={this.state.currentTrackId}
            highlightedAlbumId={this.state.highlightedAlbumId}
            showOverview={true}
            onOverviewClickTrack={this.jumpToTrack}
            onOverviewClickAlbum={this.jumpToAlbum}
            onOverviewClickClose={this.hideOverview}
            overviewAlbumId={this.state.highlightedAlbumId}
            onOverviewPrev={this.highlightPrevTrack}
            onOverviewNext={this.highlightNextTrack}
            overviewMoreLeft={moreLeft}
            overviewMoreRight={moreRight}
            animateTo={this.animateTo}
          />
        </div>
        <div className="top-bar-shadow" />
        <div className="bottom-bar" />
        <div className="years-display-container">
          <div className="years-display-wrapper">
            <YearsDisplayHorizontal
              year={centeredYear}
              onClick={this.onYearClick}
            />
          </div>
        </div>
        <div className="zoom-button" onClick={this.handleZoom}></div>
        {eventForModal && (
          <LyricsModal
            track={eventForModal}
            content="info"
            markdown={true}
            onClose={this.hideEventModal}
          />
        )}
        {photoModalState && (
          <PhotoModal
            darkBackground={true}
            photo={photoModalState.current}
            collection={photoModalState.collection}
            location={this.props.location}
            onUpdate={this.photoModalOnUpdate}
            onDismiss={this.hidePhotoModal}
          />
        )}
      </div>
    );
  }
}

export default withRouter(TimelineMonthsContainer);
