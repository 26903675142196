import _                 from 'lodash'
import {compareItems}    from './util/compare-release-date'
import {getTracksByYear,
        getTrackById,
        getAlbumById}    from './drawer-items'

import toTimestamp       from './util/to-timestamp'


const colCount = 20 /* pages are 720px wide,  track indicators are ~36px wide */
function makeColumns(n){
    let count = n, result = []
    while (count--) result.push([]);
    return result
}

function percentagePosition(idx){
    let max = colCount - 1
    return ((idx-0.5) / max) * 100
}

function collision(track, col) {
    if (!col.length) return false
    if (col.length >= 10) return true
    let other = col[col.length - 1]
    return (track.releaseDate.day !== other.releaseDate.day)
}

function tryShiftLeft(cols) {
    if (!cols[0].length) {
        cols.shift()
        cols.push([])
    }
}

function positionFromLeft(track, cols){
    let {day} = track.releaseDate
    let pct   = day / 15 /* approx half-month */
    let max   = (colCount/2) - 1
    let idx   = Math.floor(pct * max)
    while (collision(track, cols[idx])) {
        if (idx === max) {
            tryShiftLeft(cols)
            break
        }
        idx++
    }
    cols[idx].push(track)
    track._percentagePosition =  percentagePosition(idx)
}

function tryShiftRight(cols) {
    if (!cols[cols.length - 1].length) {
        cols.pop()
        cols.unshift([])
    }
}

function positionFromRight(track, cols) {
    let {day} = track.releaseDate
    let pct   = (day - 15) / 16 /* approx half-month */
    let max   = (colCount/2) - 1
    let idx   = Math.floor(pct * max)
    while (collision(track, cols[idx])) {
        if (idx === 0) {
            tryShiftRight(cols)
            break
        }
        idx--
    }
    cols[idx].push(track)
    track._percentagePosition =  percentagePosition(idx + (colCount / 2)) /* right half */
}


//TODO: memoize this?
export function tracksForYearAndMonth(year, month){

    let tracks = getTracksByYear(year)
    tracks = _.filter(tracks, track => {
        return track.releaseDate.month === month &&
               !track.listOnly && !track.hiddenTrack
    })
    tracks = tracks.sort(compareItems)

    let firstHalfTracks = []
    let secondHalfTracks = []

    for (let t of tracks) {
        if (t.releaseDate.day <= 15) firstHalfTracks.push(t);
        else secondHalfTracks.push(t);
    }
    secondHalfTracks = secondHalfTracks.reverse()

    if (!tracks.length) return null

    let firstHalf  = makeColumns(10)
    for (let track of firstHalfTracks) {
        positionFromLeft(track, firstHalf)
    }

    let secondHalf = makeColumns(10)
    for (let track of secondHalfTracks) {
        positionFromRight(track, secondHalf)
    }

    let columns = firstHalf.concat(secondHalf)

    columns.forEach(column => column.sort(compareItems))

    return columns
}

export function percentageForTrack(track) {
    if (typeof track._percentagePosition === 'undefined') {
        let {month, year} = track.releaseDate
        //called for side effects
        tracksForYearAndMonth(year, month)
    }
    return track._percentagePosition
}

function timestamp(track) {
  if (!track) return null;
    if (!track?.timestamp) {
      track.timestamp = toTimestamp(track.releaseDate);
    }
  return track.timestamp;
}

//idA is whatever is currently showing 'info', which may be nothing.
//it is assumed that idB is present on the album when needed.
function selectTrackId(albumId, idA, idB) {
  if (!idA) return idB;
  if (getTrackById(idA).presentOn[albumId]) return idA;
  return idB;
}

function findRelatedTrack(albumId, trackId, altTrackId, direction) {
  let id = selectTrackId(albumId, trackId, altTrackId);
  let album = getAlbumById(albumId);
  let tracks = album.tracksForTimeline;
  let interval = 1000 * 60 * 60 * 24 * 5; //5 days in milliseconds

  let idx = _.findIndex(tracks, { id });
  let track = tracks[idx];
  console.log("idx", idx, "track", track, "id", id);
  console.log("track", track, "tracks", tracks);
  let start = timestamp(track);

  let found;
  let i = idx + direction;
  while (i >= 0 && i < tracks.length) {
    if (Math.abs(start - timestamp(tracks[i])) >= interval) {
      found = tracks[i];
      break;
    }
    i += direction;
  }
  return found;
}



export function findPreviousTrackToNavigate(albumId, trackId, altTrackId){
    return findRelatedTrack(albumId, trackId, altTrackId, -1)
}
export function findNextTrackToNavigate(albumId, trackId, altTrackId) {
    return findRelatedTrack(albumId, trackId, altTrackId, +1)
}


//gets called during animation, so needs to be performant
let navigationCache = {}
export function availableTracksToNavigate(albumId, trackId, altTrackId) {

    let id = selectTrackId(albumId, trackId, altTrackId)

    if (albumId && id) {

        if (!navigationCache[albumId]) {
            navigationCache[albumId] = navigationCache[albumId] || {}
        }
        if (!navigationCache[albumId][id]) {
            let moreLeft  = !!findPreviousTrackToNavigate(albumId, trackId, altTrackId),
                moreRight = !!findNextTrackToNavigate(albumId, trackId, altTrackId)

            navigationCache[albumId][id] = {moreLeft, moreRight}
        }

        return navigationCache[albumId][id]

    } else {
        return {moreLeft:false, moreRight:false}
    }

}
